import React from "react";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { getHourFormat24 } from "../../timeUtils";
import { useDenseViewContext } from "./DenseViewContext";
import ReadMore from "../read_more";

const ShiftLogContainer = ({ shiftLog }) => {
  const { state: filterFields } = useDenseViewContext();

  return (
    <div
      className="box "
      id="client-shift-log-container"
      data-explorator_test_id="client-shift-log-container"
    >
      <div className="log-display">
        <div>
          {filterFields.clientName && (
            <a className="client-name" href={getClientPath(shiftLog)}>
              {shiftLog.client_name}
            </a>
          )}
        </div>
        <div className="hover-field">
          <a href={getEditShiftLogPath(shiftLog)} className="button is-primary" id="edit-icon">
            <i className="fas fa-edit"></i>
          </a>
          <p className="hover-text">edit</p>
        </div>
      </div>
      <div className={`seen-log ${shiftLog.seen ? "is-seen" : "is-not-seen"}`}>
        <b>{shiftLog.seen ? "Seen" : "Not seen"}</b>
      </div>

      <div className="log-display">
        <div>
          {filterFields.shiftNumber && (
            <>
              <b>Shift Number:</b>
              {` ${shiftLog.shift_num}`}
            </>
          )}
        </div>
        <div>
          {filterFields.location && (
            <>
              <b>Location:</b>
              {` ${shiftLog.location}`}
            </>
          )}
        </div>
      </div>
      <div className="log-display">
        {filterFields.whatHappened && (
          <div>
            <b>Happened at: </b>
            <i className="fas fa-clock"></i>
            {` ${getFormattedDateTime(shiftLog)}`}
          </div>
        )}
        {filterFields.loggedBy && (
          <div>
            <b>Logged by: </b>
            <p className="highlight">{shiftLog.logged_by}</p>
          </div>
        )}
      </div>
      <div className="log-rows">
        {filterFields.mealPrep && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.meal_prep)}`}></span>
            <b>Meal Prep:</b>
            <div className="log-rows__text">{shiftLog.meal_prep_text}</div>
          </>
        )}

        {filterFields.laundry && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.laundry)}`}></span>
            <b>Laundry:</b>
            <div className="log-rows__text">{shiftLog.laundry_text}</div>
          </>
        )}

        {filterFields.goods && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.goods)}`}></span>
            <b>Goods Distributions:</b>
            <div className="log-rows__text">{shiftLog.goods_text}</div>
          </>
        )}
        {filterFields.bedChecks && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.bed_checks)}`}></span>
            <b>Bed Checks:</b>
            <div className="log-rows__text">{shiftLog.bed_checks_text}</div>
          </>
        )}

        {filterFields.personalCare && (
          <>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.personal_care)}`}
            ></span>
            <b>Personal Care:</b>
            <div className="log-rows__text">{shiftLog.personal_care_text}</div>
          </>
        )}

        {filterFields.roomCleaning && (
          <>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.room_cleaning)}`}
            ></span>
            <b>Room Cleaning:</b>
            <div className="log-rows__text">{shiftLog.room_cleaning_text}</div>
          </>
        )}

        {filterFields.shower && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.shower)}`}></span>
            <b>Shower:</b>
            <div className="log-rows__text">{shiftLog.shower_text}</div>
          </>
        )}

        {filterFields.shave && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.shave)}`}></span>
            <b>Shave:</b>
            <div className="log-rows__text">{shiftLog.shave_text}</div>
          </>
        )}

        {filterFields.hairWash && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hair_wash)}`}></span>
            <b>Hair Wash:</b>
            <div className="log-rows__text">{shiftLog.hair_wash_text}</div>
          </>
        )}

        {filterFields.hairCut && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.hair_cut)}`}></span>
            <b>Hair Cut:</b>
            <div className="log-rows__text">{shiftLog.hair_cut_text}</div>
          </>
        )}

        {filterFields.periCare && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.peri_care)}`}></span>
            <b>Peri Care:</b>
            <div className="log-rows__text">{shiftLog.peri_care_text}</div>
          </>
        )}

        {filterFields.nailCare && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.nail_care)}`}></span>
            <b>Nail Care:</b>
            <div className="log-rows__text">{shiftLog.nail_care_text}</div>
          </>
        )}

        {filterFields.bedLinenChange && (
          <>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.bed_linen_change)}`}
            ></span>
            <b>Bed Linen Change:</b>
            <div className="log-rows__text">{shiftLog.bed_linen_change_text}</div>
          </>
        )}

        {filterFields.clothingChange && (
          <>
            <span
              className={`log-rows__icon ${getMaintenanceClass(shiftLog.clothing_change)}`}
            ></span>
            <b>Clothing Change:</b>
            <div className="log-rows__text">{shiftLog.clothing_change_text}</div>
          </>
        )}

        {filterFields.vitals && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.vitals)}`}></span>
            <b>Vitals:</b>
            <div className="log-rows__text">{shiftLog.vitals_text}</div>
          </>
        )}

        {filterFields.medication && (
          <>
            <span className={`log-rows__icon ${getMaintenanceClass(shiftLog.medication)}`}></span>
            <b>Prescribed Meds Given?:</b>
            <div className="log-rows__text">{shiftLog.medication_text}</div>
          </>
        )}
      </div>

      {filterFields.whatHappened && (
        <div className="log-what-happened">
          <b>What happened?</b>
          {shiftLog.log_description ? <ReadMore text={shiftLog.log_description} /> : <p>N/A</p>}
        </div>
      )}
    </div>
  );
};

ShiftLogContainer.propTypes = {
  shiftLog: PropTypes.object.isRequired
};

export default ShiftLogContainer;

function getEditShiftLogPath(shiftLog) {
  const location = shiftLog.location;
  const date = shiftLog.log_date;
  const shiftNum = shiftLog.shift_num;
  const id = shiftLog.id;

  const url = new URL(`/shift_logs/${id}/edit`, window.location.origin);
  url.searchParams.append("date", date);
  url.searchParams.append("location", location);
  url.searchParams.append("shift_num", shiftNum);

  return url.toString();
}
function getClientPath(shiftLog) {
  return new URL(`/clients/${shiftLog.client_id}/`, window.location.origin);
}

function getFormattedDateTime(shiftLog) {
  const minute = shiftLog.minute;
  const am_pm = shiftLog.am_pm;
  const hour = getHourFormat24(shiftLog.hour, am_pm);
  const log_date = shiftLog.log_date;

  return dayjs(`${hour || ""}:${minute || ""} ${log_date}`).format("HH:mm YYYY-MM-DD");
}

function getMaintenanceClass(maintenance) {
  return maintenance ? "fa fa-check log-rows__icon__green" : "fa fa-times log-rows__icon__red";
}
