import React, { useEffect, useRef, useState } from "react";
import { useDenseViewContext } from "./DenseViewContext";

const CustomSelect = () => {
  const { state, dispatch } = useDenseViewContext();
  const [selectAll, setSelectAll] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  useEffect(() => {
    const fields = [
      "shower",
      "shave",
      "hairWash",
      "hairCut",
      "periCare",
      "nailCare",
      "bedLinenChange",
      "clothingChange",
      "medication",
      "mealPrep",
      "laundry",
      "goods",
      "bedChecks",
      "personalCare",
      "roomCleaning",
      "vitals"
    ];
    const selectAll = fields.every((field) => state[field]);
    setSelectAll(selectAll);
  }, [state]);

  const dropdownContainerRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownContainerRef.current && !dropdownContainerRef.current.contains(e.target)) {
        setShowDropdown(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="custom-select">
      <div className="button custom-select-btn" onClick={() => setShowDropdown(!showDropdown)}>
        Select All...
        <i className="fas fa-caret-down"></i>
      </div>
      <div ref={dropdownContainerRef} className="dropdown-container">
        {showDropdown && (
          <div className="custom-select-dropdown">
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={(e) => {
                    setSelectAll(e.target.checked);
                    if (e.target.checked) {
                      dispatch({ type: "selectAll" });
                    } else {
                      dispatch({ type: "deselectAll" });
                    }
                  }}
                />
                Select All
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.shower}
                  onChange={() => {
                    dispatch({ type: "shower", payload: !state.shower });
                  }}
                />
                Shower
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.shave}
                  onChange={() => {
                    dispatch({ type: "shave", payload: !state.shave });
                  }}
                />
                Shave
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.hairWash}
                  onChange={() => {
                    dispatch({ type: "hairWash", payload: !state.hairWash });
                  }}
                />
                Hair Wash
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.hairCut}
                  onChange={() => {
                    dispatch({ type: "hairCut", payload: !state.hairCut });
                  }}
                />
                Hair Cut
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.periCare}
                  onChange={() => {
                    dispatch({ type: "periCare", payload: !state.periCare });
                  }}
                />
                Peri Care
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.nailCare}
                  onChange={() => {
                    dispatch({ type: "nailCare", payload: !state.nailCare });
                  }}
                />
                Nail Care
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.bedLinenChange}
                  onChange={() => {
                    dispatch({
                      type: "bedLinenChange",
                      payload: !state.bedLinenChange
                    });
                  }}
                />
                Bed Linen Change
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.clothingChange}
                  onChange={() => {
                    dispatch({
                      type: "clothingChange",
                      payload: !state.clothingChange
                    });
                  }}
                />
                Clothing Change
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.medication}
                  onChange={() => {
                    dispatch({ type: "medication", payload: !state.medication });
                  }}
                />
                Medication
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.mealPrep}
                  onChange={() => {
                    dispatch({ type: "mealPrep", payload: !state.mealPrep });
                  }}
                />
                Meal Prep
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.laundry}
                  onChange={() => {
                    dispatch({ type: "laundry", payload: !state.laundry });
                  }}
                />
                Laundry
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.goods}
                  onChange={() => {
                    dispatch({ type: "goods", payload: !state.goods });
                  }}
                />
                Goods
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.bedChecks}
                  onChange={() => {
                    dispatch({ type: "bedChecks", payload: !state.bedChecks });
                  }}
                />
                Bed Checks
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.personalCare}
                  onChange={() => {
                    dispatch({ type: "personalCare", payload: !state.personalCare });
                  }}
                />
                Personal Care
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.roomCleaning}
                  onChange={() => {
                    dispatch({ type: "roomCleaning", payload: !state.roomCleaning });
                  }}
                />
                Room Cleaning
              </label>
            </div>
            <div>
              <label className="checkbox">
                <input
                  type="checkbox"
                  checked={state.vitals}
                  onChange={() => {
                    dispatch({ type: "vitals", payload: !state.vitals });
                  }}
                />
                Vitals
              </label>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CustomSelect.propTypes = {};
export default CustomSelect;
