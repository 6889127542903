import React from "react";
import PropTypes from "prop-types";
import FieldsFilter from "./FieldsFilter";
import DenseViewContextProvider from "./DenseViewContext";
import ShiftLogList from "./shift_log_lists";

const DenseView = ({ shiftLogs }) => {
  return (
    <DenseViewContextProvider>
      <FieldsFilter />
      <ShiftLogList shiftLogs={shiftLogs} />
    </DenseViewContextProvider>
  );
};

DenseView.propTypes = {
  shiftLogs: PropTypes.array.isRequired
};

export default DenseView;
