import React, { createContext, useContext, useReducer } from "react";
import PropTypes from "prop-types";

const initialState = {
  clientName: true,
  shiftNumber: true,
  location: true,
  loggedBy: true,
  whatHappened: true,
  shower: true,
  shave: true,
  hairWash: true,
  hairCut: true,
  periCare: true,
  nailCare: true,
  bedLinenChange: true,
  clothingChange: true,
  medication: true,
  mealPrep: true,
  laundry: true,
  goods: true,
  bedChecks: true,
  personalCare: true,
  roomCleaning: true,
  vitals: true,
  isSeen: "All"
};
const DenseViewContext = createContext(null);

function reducer(state, action) {
  if (action.type === "selectAll") {
    return {
      ...state,
      shower: true,
      shave: true,
      hairWash: true,
      hairCut: true,
      periCare: true,
      nailCare: true,
      bedLinenChange: true,
      clothingChange: true,
      medication: true,
      mealPrep: true,
      laundry: true,
      goods: true,
      bedChecks: true,
      personalCare: true,
      roomCleaning: true,
      vitals: true
    };
  }
  if (action.type === "deselectAll") {
    return {
      ...state,
      shower: false,
      shave: false,
      hairWash: false,
      hairCut: false,
      periCare: false,
      nailCare: false,
      bedLinenChange: false,
      clothingChange: false,
      medication: false,
      mealPrep: false,
      laundry: false,
      goods: false,
      bedChecks: false,
      personalCare: false,
      roomCleaning: false,
      vitals: false
    };
  }

  return { ...state, [action.type]: action.payload };
}

function useDenseViewContext() {
  const context = useContext(DenseViewContext);
  return context;
}

function DenseViewContextProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <DenseViewContext.Provider value={{ state, dispatch }}>
      {props.children}
    </DenseViewContext.Provider>
  );
}
DenseViewContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default DenseViewContextProvider;
export { useDenseViewContext };
